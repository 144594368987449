<template>
  <section class="appointment-payment-method">
    <div class="appointment-payment-method__box">
      <h2 class="appointment-payment-method__title">
        Método de pagamento
      </h2>

      <div class="appointment-payment-method__wrapper">
        <div
          v-for="(method, index) in paymentMethods"
          :key="`payment-method-${index}`"
          :aria-checked="method.name === payment.method"
          role="checkbox"
          :class="[
            { 'appointment-payment-method__method--checked': method.name === payment.method.name },
            getClassName(index)
          ]"
          class="appointment-payment-method__method"
          @click="setPayment({ method })"
        >
          <icon
            v-if="method.name !== 'prepaid'"
            :name="method.icon"
            color="gray"
            class="appointment-payment-method__method-icon"
          />

          <img
            v-if="method.name === 'prepaid'"
            class="appointment-payment-method__method-logo"
            :src="require(`@/assets/img/logo/${partner.logo.header}`)"
          >

          <p class="appointment-payment-method__method-text">
            {{ method.label }}
          </p>
        </div>

        <div
          v-if="paymentMethods.length > 4"
          class="break"
        />
      </div>
    </div>

    <div class="appointment-payment-method__disclaimer">
      <h3 class="appointment-payment-method__title appointment-payment-method__title--left">
        Importante
      </h3>

      <div
        v-for="(method, index) in paymentMethods"
        :key="`payment-method-helper-${index}`"
        class="appointment-payment-method__disclaimer-method"
      >
        <h5 class="appointment-payment-method__disclaimer-title">
          {{ method.label }}
        </h5>

        <p class="appointment-payment-method__disclaimer-text">
          {{ method.helperText | replaceString({ str: '[partnerName]', val: partner.name }) }}
        </p>
      </div>
    </div>

    <div class="appointment-payment-method__call-to-action">
      <ui-button
        :disabled="!payment.method.name"
        label="Prosseguir"
        @click="goToDate"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import completeStep from '@/mixins/completeStep';
import scrollToSection from '@/mixins/scrollToSection';
import UiButton from '@/components/ui/Button.vue';
import Icon from '@/components/ui/Icon.vue';

export default {
  name: 'AppointmentSchedulingPaymentMethod',

  filters: {
    replaceString(text, { str = '', val = null } = {}) {
      return text.replace(str, val);
    },
  },

  mixins: [completeStep, scrollToSection],

  components: {
    UiButton,
    Icon,
  },

  computed: {
    ...mapGetters('scheduling', ['payment']),
    ...mapGetters('partner', ['paymentMethods', 'partner']),
  },

  watch: {
    'payment.method.name': {
      deep: false,
      immediate: false,
      handler(value) {
        if (value) {
          this.scrollToSection('.appointment-payment-method__call-to-action');
        }
      },
    },
  },

  mounted() {
    if (document.domain === 'paciente.drconecta.com.br') {
      window.fbq('track', 'PatientViewPaymentMethods');
    }
  },

  methods: {
    ...mapActions('ui', ['toggleProgressBar']),
    ...mapActions('scheduling', ['setPayment']),

    goToDate() {
      this.toggleProgressBar();
      this.completeStep(2);
      this.toggleProgressBar(false);
    },

    getClassName(index) {
      if (index <= 2) {
        return `flex-order-${index + 1}`;
      }

      return `flex-order-${index + 2}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.break {
  @screen md {
    @apply h-0 p-0 m-0;
    flex: 0 0 100%;
    order: 4;
  }
}

@for $i from 1 through 7 {
  .flex-order-#{$i} {
    order: #{$i};
  }
}
</style>
